<template>
  <div class="my-3">
    <Details
      :left="'ご利用料金'"
      :aClass="'font-weight-bold --medium-gray col-8 pb-2'"
    >
    <template v-slot:right v-if="feeSummary">
        <div class="fee-summary">
          <div v-if="feeSummary.chargeLines.length" class="summary-lines charge-lines">
            <div class="fee-summary-title">ご利用明細</div>
            <div class="fee-summary-details">
              <div class="fee-summary-line" v-for="(line, ind) in feeSummary.chargeLines" :key="ind">
                <span>{{ line.formatted }}</span>
              </div>
            </div>
          </div>
          <div v-if="!hidePayments && feeSummary.paymentLines.length" class="summary-lines payment-lines">
            <div class="fee-summary-title">精算方法</div>
            <div class="fee-summary-details">
              <div class="fee-summary-line" v-for="(line, ind) in feeSummary.paymentLines" :key="ind">
                <span>{{ line.formatted }}</span>
              </div>
            </div>
          </div>
          <div v-if="feeSummary.alerts.length" class="summary-alerts">
            <div class="alert" v-for="(item, ind) in feeSummary.alerts" :key="ind">
              <div class="alert">{{ item.message }}</div>
            </div>
          </div>
        </div>
      </template>
    </Details>
  </div>
</template>

<script>
import Details from './index'
import gql from 'graphql-tag'

export default {
  components: {
    Details
  },
  props: {
    bookingId: Number,
    hidePayments: Boolean
  },
  data () {
    return {
      feeSummary: null
    }
  },
  watch: {
    bookingId: {
      immediate: true,
      async handler () {
        if (!this.bookingId) {
          this.feeSummary = null
          return
        }
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const data = await this.$apollo.query({
              query: gql`query calculateMyFeeSummary ($bookingId: Int!) {
                calculateMyFeeSummary (bookingId: $bookingId) {
                  chargeLines { formatted }
                  paymentLines { formatted }
                  alerts { message }
                }
              }`,
              variables: {
                bookingId: this.bookingId
              },
              fetchPolicy: 'no-cache'
            })

            this.feeSummary = data.data.calculateMyFeeSummary
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fee-summary {
  gap: 1em;
  display: flex;
  flex-direction: column;
}

.fee-summary-details {
  padding-left: 1em;
}
</style>
