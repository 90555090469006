<template>
  <div :class="[!notWhiteBg ? 'bg-white' : '', cardClass]">
    <div class="pa-4 p-relative">
      <v-container>
        <v-row >
          <v-col class="text-title --prm font-weight-bold pa-0">
            {{ left }}
          </v-col>

          <v-col class="pa-0 word-break" :class="aClass">
            <format-span v-if="formatText" :value="right" />
            <template v-else>
              {{ right }}
            </template>
            <slot name="right" />
          </v-col>

        </v-row>
      </v-container>

    </div>
    <v-divider v-if="divider" class="mx-4 bg-dark-silver"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'ReservationDetailItem',
  props: {
    right: [Number, String],
    left: String,
    cardClass: {
      String,
      default: ''
    },
    aClass: {
      String,
      default: ''
    },
    divider: {
      Boolean,
      default: false
    },
    notWhiteBg: {
      Boolean,
      default: false
    },
    link: {
      type: String,
      default: '/'
    },
    formatText: {
      type: Boolean,
      default: false
    }

  }
}
</script>
